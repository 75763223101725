import type { ShopperBasketsTypes } from "commerce-sdk-isomorphic";

import type { Prettify, RemoveStringIndex } from "~/lib/utils-types";

export type BasketModelType = BasketModel;
export class BasketModel {
  data: Prettify<RemoveStringIndex<ShopperBasketsTypes.Basket>>;
  constructor(data: Prettify<RemoveStringIndex<ShopperBasketsTypes.Basket>>) {
    this.data = data;
  }

  get basketId() {
    return this.data.basketId;
  }
  get customerInfo() {
    return this.data.customerInfo;
  }
  get shipments() {
    return this.data.shipments;
  }
  get currency() {
    return this.data.currency;
  }
  get productItems() {
    return this.data.productItems;
  }
  get taxTotal() {
    return this.data.taxTotal;
  }

  get productTotal() {
    return this.data.productTotal;
  }
  get shippingTotal() {
    return this.data.shippingTotal;
  }
  get orderTotal() {
    return this.data.orderTotal;
  }
  get paymentInstruments() {
    return this.data.paymentInstruments;
  }
  get productSubTotal() {
    return this.data.productSubTotal;
  }
  get orderPriceAdjustments() {
    return this.data.orderPriceAdjustments;
  }
}
